
import { defineComponent } from 'vue';
import PageHeader from '../components/PageHeader.vue'
import { getRooms, ParsedItem } from '../lib/Contentful'

interface RoomData {
  rooms: ParsedItem[];
  location: string;
}

export default defineComponent({
  name: 'Home',
  components: {
    PageHeader
  },
  data(): RoomData {
    return {
      rooms: [],
      location: window.location.pathname.split("/")[1]
    }
  },
  async mounted() {
    await this.getRooms(this.location)
    const w = window as any;
    w.fbq('track', 'ViewContent');
  },
  methods: {
    async getRooms(location: string) {
      this.rooms = await getRooms(location)
    }
  }
});
